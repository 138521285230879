import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import Layout from "../components/layout/Layout";
import { ProductPricing, FAQ } from "../components/PricingComponents";
import { ButtonSymbolHover } from "../components/Button";

import { pushSAEvent } from "../helpers";

export default ({
    data,
    location,
    pageContext
}) => {
    pushSAEvent('visit_pricing');

    const isLocaleDe = () => pageContext.locale === 'de';

    const getTranslation = (key) => {
        if (key === 'title') {
            return isLocaleDe() ? 'Preisgestaltung' : 'Pricing';
        }
        if (key === 'description') {
            return isLocaleDe()
                ? 'Entdecken Sie das Preismodell von Frends und wählen Sie Ihr Paket auf Basis Ihrer Anforderungen.'
                : 'Discover the pricing model of Frends and choose the correct tier based on your requirements.';
        }
        return key;
    }

    return (
        <Layout
            title={getTranslation('title')}
            description={getTranslation('description')}
            transparentNavigation={true}
            isDark={true}
            pageContentId="pricing-content"
            location={location}
            language={pageContext.locale}
        >
            <main
                id="pricing-content"
                style={{ marginTop: "-80px" }}
            >
                <HeroSection
                    bg={data.bg}
                    locale={pageContext.locale}
                />

                <PricingSection
                    initialProducts={pageContext.products}
                    startTrialLink={isLocaleDe() ? undefined : "/trial"}
                    locale={pageContext.locale}
                />

                <FAQSection locale={pageContext.locale} />
            </main>
        </Layout>
    );
};

const HeroSection = ({ bg, locale }) => {
    const isLocaleDe = () => locale === 'de';

    const getTranslation = (key) => {
        if (key === 'title') {
            return isLocaleDe() ? 'Frends Preismodell' : 'Frends Pricing';
        }
        if (key === 'description') {
            return isLocaleDe()
                ? 'Integrieren und automatisieren Sie Ihre Prozesse mit den Startup- und Business-Paketen von Frends. Darüber hinaus bieten wir ein Basic-Paket für einzelne Anwendungsfälle sowie Enterprise-Support für komplexe und zentralisierte iPaaS-Nutzung an.'
                : 'Integrate and automate your business with the Startup and Business Tiers of Frends. We also offer a lightweight Frends Basic option for single use cases and Enterprise support for demanding and centralized iPaaS usage.';
        }
        return key;
    }

    return (
        <section
            className="section is-medium"
            style={{ position: 'relative', paddingTop: '16.375rem', paddingBottom: '7.625rem' }}
        >
            <GatsbyImage
                image={getImage(bg)}
                style={{ width: "100%", position: "absolute", bottom: "0", left: "0", zIndex: "-1", height: "100%" }}
                alt=""
            />
            <div className="container has-text-centered">
                <h1 className="font-bold size-100 is-black">
                    {getTranslation('title')}
                </h1>
                <div className="columns">
                    <div className="column is-half is-offset-one-quarter margin-top-30">
                        <p
                            className="is-black font-regular size-20"
                            style={{ marginBottom: "10px" }}
                        >
                            {getTranslation('description')}
                        </p>
                    </div>
                </div>

                {isLocaleDe() && (
                    <div className="columns">
                        <div className="column margin-top-30 hero-links-column">
                            <ButtonSymbolHover
                                color="purple"
                                type="outlined"
                                to="https://share-eu1.hsforms.com/1v2j9D4QHTdOwuz_tcbJOzQ2b65dy"
                                text="Jetzt Termin buchen"
                                outerLink={true}
                            />
                        </div>
                    </div>
                )}
            </div>
        </section>
    );
}

const PricingSection = ({
    initialProducts,
    startTrialLink,
    locale
}) => (
    <section className="section">
        <ProductPricing
            initialProducts={initialProducts}
            startTrialLink={startTrialLink}
            locale={locale}
        />
    </section>
);

const FAQSection = ({ locale }) => (
    <section className="section">
        <div className="container">
            <FAQ locale={locale} />
        </div>
    </section>
);

export const query = graphql`query {
    bg: file(relativePath: {eq: "images/pricing_bg.png"}) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
  }`;
