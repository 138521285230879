import React from "react";

import ContentLoadingIndicator from "./ContentLoadingIndicator";

const ContentButton = ({
    onClick,
    type,
    color,
    disabled,
    margin,
    width,
    smallText,
    children
}) => (
    <button
        className={`frends-button rounded is-outlined-circle ${color ? color : 'is-purple'}`}
        style={{ margin: margin || '0', width: width || 'fit-content' }}
        onClick={onClick}
        type={type}
        disabled={disabled}
    >
        <span className={`${smallText && 'size-16'}`}>
            {children}
        </span>
    </button>
);

export const SubmitButton = ({
    loading,
    onClick,
    children,
    ...rest
}) => (
    <>
        {loading ? (
            <ContentLoadingIndicator paddingLeft="1.5rem" />
        ) : (
            <ContentButton
                {...rest}
                type="submit"
                onClick={(e) => onClick(e)}
                
            >
                {children}
            </ContentButton>
        )}
    </>
);

export default ContentButton;